import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Combobox } from '@dv01-inc/waterfall-ui';
import { optionsArray } from '../../data/optionsArray.ts';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Code</AnchorLink>
      <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`A ComboBox displays a searchable text box combined with a ListBox, which enables the user to select multiple items from the list or enter a new value. In some implementations, the popup presents allowed values, while in other implementations, the popup presents suggested values, and users may either select one of the suggestions or type a value.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Combobox allows users to search and select multiple items. In some implementations, a CTA button will be displayed in the bottom of the populated dropdown menu.`}</p>
    <h2>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Combobox type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Default`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`With CTA`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Multi Select`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Code`}</h2>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/combobox src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/combobox/Combobox.tsx",
            "path": "src/combobox",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/combobox/Combobox.tsx"
          }}>{`<Combobox options={optionsArray}/>
`}</code></pre>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      